js-gallery {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: center;
}

js-gallery article {
    padding: 1rem;
    margin: 0;
    background: var(--tertiary-color);
    box-shadow: 1px 1px 8px var(--card-color);
    border: 1px solid var(--card-color);
    max-width: 280px;
    text-align: center;
    font-family: var(--font-text);
    display: flex;
    flex-flow: row wrap;
}

js-gallery article > * {
    width: 100%;
}

js-gallery article header {
    font-family: var(--font-headline);
    display: flex;
    flex-flow: row wrap;
    Justify-content: center;
    height: 380px;
}

js-gallery article header img {
    cursor: pointer;
    max-width: 200px;
    align-self: center;
}

js-gallery article header h3 {
    align-self: flex-end;
}

js-gallery article section {
    margin-bottom: 1rem;
}

js-gallery article footer {
    align-self: end;
}

js-gallery article footer a {
    border: 1px solid var(--card-color);
    border-radius: 5px;
    padding: .5rem 1rem;
    box-shadow: 1px 1px 8px var(--card-color);
    display: inline-block;
    text-decoration: none;
    font-family: var(--font-headline);
    transition: .3s;
    margin-bottom: .5rem;
}

js-gallery article footer a:last-child {
    margin-bottom: 0;
}

js-gallery article footer a img {
    width: 13px;
    height: 13px;
}

js-gallery article footer > a:hover {
    box-shadow: none;
}

js-gallery article header img {
    max-width: 200px;
}

.overlay {
    width: 0;
    height: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    left: -500px;
    text-align: center;
    background: rgba(0,0,0,.4);
    transition: .5s;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.overlay.show {
    top: 0;
    left: 0;
    opacity: 100;
    width: 100%;
    height: 100vh;
}

.overlay figure {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay figure img {
    height: auto;
    max-height: 80vh;
    max-width: 100%;
}

.overlay > a {
    display: block;
    padding: .5rem;
    background: var(--card-color);
    border: 1px solid var(--card-color);
    border-radius: 5px;
    box-shadow: 0px 0px 4px var(--card-color);
    margin: 0 1rem;
}

.overlay > a.close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: .5rem 1rem;
}
