* {
    box-sizing: border-box;
}

:root {
    --primary-color: #484848;
    --secondary-color: #fff;
    --tertiary-color: #fff;
    --card-color: #eee;
    --font-text: sans-serif;
    --font-headline: 'Voltaire', sans-serif;
    background: var(--secondary-color);
    color: var(--primary-color);
    font-family: var(--font-text);
}

body, html {
    min-height: 100%;
}

header {
    font-family: var(--font-headline);
}

main, .wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

main > section {
    margin-bottom: 3rem;
}

h1 {
    font-family: var(--font-headline);
}

h2 {
    border-bottom: 1px solid var(--card-color);
    padding-bottom: 0.5rem;
    font-family: var(--font-headline);
}

dl {
    font-size: .7rem;
}
dt, dd {
    margin: 0;
}
dt {
    font-weight: 700;
}
dd {
    font-weight: 400;
    margin-bottom: .5rem;
}

dd:last-of-type {
    margin-bottom: 0;
}

a {
    text-decoration: none;
    color: rgb(85, 26, 139);
    transition: .5s;
}

a:hover {
    color: rgb(161, 73, 59);
}

a.disabled {
    color: gray;
    cursor: default;
}