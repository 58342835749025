body > header {
    background-image: url("../img/karten/01-2024-hh-speicherstadt.jpg");
    background-position: center;
    padding: 2rem 0;
    margin-bottom: 2rem;
}

body > header .headline {
    padding: 1rem;
    background: rgba(255,255,255,.8);
    box-shadow: 0px 0px 8px var(--card-color);
    display: inline-block;
}

body > header h1 {
    margin: 0;
}

body > header p {
    margin: 0;
}